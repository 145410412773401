import React, { useRef } from 'react'
import { useRouter } from 'next/router'

import { colors, spaces } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import { RoutineItem } from '../Items'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import styled from 'styled-components'

const SwiperPagination = styled.div`
  text-align: center;
  .swiper-pagination-bullet{
    margin: 0 2px 0 2px;
  }
  .swiper-pagination-bullet-active {
    background-color: #002587 !important
  }
`
const ThemeRoutineList = ({ list, subTitle }) => {
  const router = useRouter()

  const movePage = (page) => {
    router.push(page)
  }

  return (
    <>
      <Grid>
        <Grid padding={`0 ${spaces.default.px}`}>
          <Text variant='subtitle2' color={colors.grey.g800}>{subTitle || '루틴'}</Text>
        </Grid>
        <Grid display='flex' marginTop='6px' gap={spaces.component.s4.px} padding={`0 ${spaces.default.px}`} height='145px' flexWrap='wrap' flexDirection='column' overflow='auto'>
          <Swiper
            slidesPerView='1'
            className='theme_routine_swiper'
            modules={[Pagination]}
            style={{
              width: '100%',
              textAlign: 'left'
            }}
            pagination={{
              el: '.theme_routine_swiper_pagination',
              clickable: true
            }}
            grabCursor
          >
            {list && list.length > 0 && list.map((routine, outerIndex) => {
              if (outerIndex % 2 === 0) {
                return (
                  <SwiperSlide
                    key={routine?.id}
                    style={{
                      width: '320px'
                    }}
                  >
                    {
                      list.map((routine, innerIndex) => {
                        if (innerIndex >= outerIndex && innerIndex <= (outerIndex + 1)) {
                          return (
                            <Grid
                              key={routine?.id + routine?.name}
                              marginTop={innerIndex === outerIndex ? '' : '16px'}
                              width='300px'
                            >
                              <RoutineItem routine={routine} onClick={() => movePage('/routine/' + routine.id)} />
                            </Grid>
                          )
                        } else {
                          return null
                        }
                      })
                    }
                  </SwiperSlide>
                )
              } else {
                return null
              }
            })}
          </Swiper>
        </Grid>
        <SwiperPagination
          className='theme_routine_swiper_pagination'
        />
      </Grid>
    </>
  )
}

export default ThemeRoutineList
