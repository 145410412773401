import React, { memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { colors, spaces } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import { useSelector } from 'react-redux'
import { RoutineItem, Tags } from '../Items'
import Icons from '../../elements/Icons'

const ThemeArea = (props) => {
  return (
    <Grid marginTop={spaces.contents.s3.px} gap='18px' overflow='hidden'>
      <Grid padding={`0 ${spaces.default.px}`}>
        {
          typeof props.title === 'string'
            ? (<Text variant='title3' color={colors.grey.g800} family='Pretendard Variable' html={props.title} />)
            : props.title
        }
      </Grid>
      {props.children.map(child => {
        return (child)
      })}
    </Grid>
  )
}

export default ThemeArea
