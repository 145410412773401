import React from 'react'
import Image from 'next/image'
import { Grid } from '../elements'
const NextImage = ({
  src,
  width,
  height,
  layout,
  objectFit,
  borderRadius,
  onClick,
  priority = false
}) => {
  if (!src) {
    return (
      <Grid
        width={width}
        height={height}
      />
    )
  }

  const sizeAttributes = {
    width: layout === 'fill' ? null : width,
    height: layout === 'fill' ? null : height
  }

  return (
    <Image
      src={src}
      layout={layout}
      objectFit={objectFit}
      {...sizeAttributes}
      onClick={onClick || null}
      style={{
        borderRadius: borderRadius,
        cursor: onClick ? 'pointer' : ''
      }}
      priority={priority}
    />
  )
}

export default NextImage
