import React, { memo, useEffect, useState, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { setAlert, setUser } from 'store/default'

import Loading from 'components/commons/Loading'
import { Grid } from 'components/elements'
import TagManager from 'react-gtm-module'

const FacebookPixel = dynamic(() => import('components/commons/FacebookPixel'), { ssr: false })

const UserLayout = memo(({ isUser = false, isAuth = false, isAlert = true, children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { user, env } = useSelector(state => state.default)
  const [isInit, setisInit] = useState(false)

  const getUserInfo = async () => {
    const token = await window.api.getAccessToken()
    if (token) {
      const { success, data } = await window.api.request('get', 'me', undefined, { preventErrorAlert: true, preventRedirectToLogin: true })
      if (success) {
        if (isAuth && !data.is_identity_verified) router.push('/?check_auth=true')
        else dispatch(setUser(data))
      } else {
        if (isUser) {
          if (isAlert) {
            dispatch(setAlert({
              body: '로그인이 필요한 페이지입니다.',
              onClick: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`),
              onClose: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
            }))
          } else {
            router.push(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
          }
        } else dispatch(setUser(null))
      }
    } else {
      if (isUser) {
        if (isAlert) {
          dispatch(setAlert({
            body: '로그인이 필요한 페이지입니다.',
            onClick: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`),
            onClose: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
          }))
        } else {
          router.push(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
        }
      } else {
        dispatch(setUser(null))
      }
    }
  }

  useEffect(() => {
    if (!!user && env) {
      TagManager.dataLayer({ dataLayer: { userId: user.id } })
    }
  }, [user, env])

  useEffect(() => {
    getUserInfo()
    setisInit(true)
  }, [])

  if (!isInit) return null

  if (!user && isUser) {
    return (
      <Grid height='90vh' align='center' justify='center'>
        <Loading />
      </Grid>
    )
  }

  return (
    <>
      {children}
      <Suspense><FacebookPixel /></Suspense>
    </>
  )
}, (p, n) => {
  if (p.children !== n.children) return false
  if (p.isUser !== n.isUser) return false
  return true
})

export default UserLayout
