import React from 'react'

import { colors, spaces } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import NextImage from '../commons/NextImage'

export const RoutineItem = ({ routine, onClick }) => {
  return (
    <Grid columns='64px 1fr' gap='16px' align='center' onClick={onClick} style={{ cursor: 'pointer' }}>
      <NextImage
        src={routine?.icon_image}
        width='128px'
        height='128px'
        borderRadius='8px'
      />
      <Grid align='center' justify='start'>
        <Text color={colors.grey.g800} variant='body2' ellipsis='1' align='left'>{routine?.name}</Text>
        <Grid marginTop={spaces.component.s1.px}><Text variant='label2' color={colors.grey.g700} ellipsis='1' align='left'>{routine?.one_line_description}</Text></Grid>
        <Grid columns='12px 1fr' gap='6px' align='center' marginTop={spaces.component.s3.px}>
          <Icons icon='account-2' width='12' height='12' color={colors.grey.g800} />
          <Text variant='caption2' color={colors.grey.g800}>{routine?.n_participants}</Text>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const RoutineItem2 = ({ routine, handleCheckRoutine }) => {
  const getWeekDaysString = () => {
    const scheduledList = [
      routine?.is_mon_scheduled,
      routine?.is_tue_scheduled,
      routine?.is_wed_scheduled,
      routine?.is_thu_scheduled,
      routine?.is_fri_scheduled,
      routine?.is_sat_scheduled,
      routine?.is_sun_scheduled
    ].map((isScheduled, index) => {
      let day = ''
      if (isScheduled) {
        switch (index) {
          case 0: day = '월'
            break
          case 1: day = '화'
            break
          case 2: day = '수'
            break
          case 3: day = '목'
            break
          case 4: day = '금'
            break
          case 5: day = '토'
            break
          case 6: day = '일'
            break
        }
      }
      return day
    }).filter(isScheduled => isScheduled !== '')
    return scheduledList.join(', ')
  }

  return (
    <Grid>
      <Button width='100%' padding='0' justifyContent='start' radius='0' overflow='visible' onClick={() => handleCheckRoutine(routine?.id)}>
        <Grid columns='24px 1fr' gap='16px' align='center'>
          <Icons icon={`check-circle${routine?.isChecked ? '' : '-unchecked'}`} width='24px' height='24px' color={routine?.isChecked ? colors.point : colors.grey.g500} />
          <Grid columns='64px 1fr' gap='16px' align='center' position='relative'>
            <Grid image={routine?.icon_image} width='64px' height='64px' borderRadius='8px' />
            <Grid align='center' justify='start'>
              <Text color={colors.grey.g800} variant='body2' ellipsis='1' align='left'>{routine?.name}</Text>
              {
              !!routine?.one_line_description && (
                <Grid marginTop={spaces.component.s1.px} justify='start'><Text variant='label2' color={colors.grey.g700} ellipsis='1' align='left'>{routine?.one_line_description}</Text></Grid>
              )
            }
              <Grid marginTop={spaces.component.s3.px} columns='16px 1fr' gap={spaces.component.s2.px} align='center'>
                <Icons icon='calendar' width='16' height='16' color={colors.grey.g800} />
                <Text variant='label2' color={colors.grey.g800}>
                  매주 {getWeekDaysString()}
                </Text>
              </Grid>
            </Grid>
            {
            !!routine?.isDuplicated && (
              <Grid position='absolute' style={{ bottom: -22 }}>
                <Text variant='label2' color={colors.error}>이미 진행중인 루틴이에요.</Text>
              </Grid>
            )
          }
          </Grid>
        </Grid>

      </Button>
    </Grid>
  )
}
export const Tags = ({ tagList, tag, changeTag }) => {
  const getTagSwiperWidth = (labelLength) => {
    return 55 + ((labelLength - 1) * 15)
  }

  return (
    <Grid display='flex' padding={`0 ${spaces.default.px} 10px`} overflow='auto'>
      <Swiper
        freeMode
        slidesPerView='auto'
        className='popular_routine_tag_swiper'
        modules={[FreeMode]}
        style={{
          marginLeft: '0px'
        }}
        grabCursor
      >
        {
          tagList && tagList.length > 0 && tagList.map(tagItem => (
            <SwiperSlide key={tagItem.key} style={{ width: getTagSwiperWidth(tagItem.label.length) }}>
              <Button padding={`${spaces.component.s3.px} ${spaces.component.s4.px}`} radius='20px' variant={tag === tagItem.key ? 'flat' : 'outline'} color={tag === tagItem.key ? colors.grey.g800 : colors.grey.g300}
                      style={{ flexShrink: 0 }}
                      onClick={() => changeTag(tagItem.key)}>
                <Text color={tag === tagItem.key ? colors.white : colors.grey.g700} isBreak={false} style={{ wordBreak: 'keep-all' }}>{tagItem.label}</Text>
              </Button>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Grid>
  )
}
