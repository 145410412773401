import React from 'react'
import { useRouter } from 'next/router'

import { colors, spaces } from 'libs/theme'
import Image from 'next/image'
import { Grid, Text } from 'components/elements'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import NextImage from '../../commons/NextImage'

const ThemeProgramList = ({ list, subTitle }) => {
  const router = useRouter()

  const movePage = (page) => {
    router.push(page)
  }
  return (
    <>
      <Grid gap='6px'>
        <Grid padding={`0 ${spaces.default.px}`}>
          <Text variant='subtitle2' color={colors.grey.g800}>{subTitle || '프로그램'}</Text>
        </Grid>
        <Grid display='flex' gap={spaces.component.s3.px} padding={`0 ${spaces.default.px}`} overflow='auto' flexWrap='nowrap'>
          <Swiper
            grabCursor
            freeMode
            slidesPerView='auto'
            className='theme_program_swiper'
            modules={[FreeMode]}
            style={{
              width: '100%'
            }}
          >
            {list && list.length > 0 && list.map(program => {
              return (
                <SwiperSlide key={program.id} style={{ width: '150px' }}>
                  <Grid
                    width='100%'
                    gap={spaces.component.s3.px}
                    justify='center'
                    columns='1fr'
                  >
                    <NextImage
                      src={program?.images?.find(image => image.type === 'COVER_SECONDARY') ? program?.images?.find(image => image.type === 'COVER_SECONDARY').image : null}
                      width='140px'
                      height='200px'
                      borderRadius='12px'
                      onClick={
                        () => movePage('/routine/program/' + program.id)
                      }
                    />
                    <Grid justify='left' width='140px'>
                      <Text
                        variant='caption2'
                        color={colors.grey.g800}
                        ellipsis='1'
                      >
                        {program?.name_secondary}
                      </Text>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Grid>
      </Grid>
    </>
  )
}

export default ThemeProgramList
